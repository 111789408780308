<template>

  <f7-page v-if="!device">
    <!-- This mean there is no devices! we will redirect user to another page without bottom tabbed views  -->
  </f7-page>

  <f7-page v-else>
    <f7-navbar transparent>
      <f7-nav-left v-if="moreDevices">
        <f7-link icon-f7="menu" style="color:#848484;" href="#" @click="chooseGarden"></f7-link>
      </f7-nav-left>

      <f7-nav-title>{{ device.name }}</f7-nav-title>

      <f7-nav-right>
        <f7-link icon-only href="/user-notifications/">
          <f7-icon f7="heart">
            <f7-badge v-if="this.$root.unread_notifications > 0" color="red">{{ this.$root.unread_notifications }}
            </f7-badge>
          </f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block-title class="like-title">
      {{ device.name }}
      <!-- <f7-link icon-f7="square_pencil" class="edit-icon" href="/edit-garden/"></f7-link> -->
    </f7-block-title>

    <!--
    <f7-card class="demo-card-header-pic" no-shadow	>
      <f7-card-header class="no-border" valign="bottom"
                      style="background-image:url(https://app.urbigo.me/uploads/slika3.png);
                      font-weight: bold; text-align: right; justify-content: flex-end">
        Urbi V2 is Here 🚀
      </f7-card-header>
      <f7-card-content>
        <p>
          Exciting news! Meet the latest member of the UrbiGrow family — Urbi Interactive Gardening Station 🎉🌿
        </p>
      </f7-card-content>
      <f7-card-footer>
        <f7-link></f7-link>
        <f7-link href="/new-announcement/" style="font-weight: bold;">Read More</f7-link>
      </f7-card-footer>
    </f7-card> -->


    <f7-block strong inset>
      <!-- device Values -->
      <f7-row>
        <f7-col>
          <f7-icon f7="sun_max" size="18px" style="color: #ffb91c;"></f7-icon>
          <span style="color: #ffb91c;">Light</span><br/>
          <span class="fs">{{ formatLight(device.daily_light_hours) }}</span>h
        </f7-col>
        <f7-col>
          <f7-icon f7="thermometer" size="18px" style="color: #fe4a04;"></f7-icon>
          <span style="color: #fe4a04;">Temp</span><br/>
          <span class="fs">{{ formatTemp(deviceValues.temp) }}</span>
          <!--<span v-if="deviceValues.temp != '-'">°C</span>-->

        </f7-col>
        <f7-col>
          <f7-icon f7="drop" size="18px" style="color: #007afe;"></f7-icon>
          <span style="color: #007afe;">Water</span><br/>
          <span class="fs">{{ getWaterLevel(deviceValues.water) }}</span>
        </f7-col>
      </f7-row>

      <!-- pre-loader for values -->
      <f7-row v-if="!$root.deviceLoaded" class="text-align-center" style="height:55px;">
        <f7-col>
          <f7-preloader :size="19" style="top:5px;"></f7-preloader>
          <p class="mb0" style="margin-top:12px;">Loading device info</p>
        </f7-col>
      </f7-row>

      <!-- not connected info -->
      <f7-row v-if="$root.deviceLoaded && deviceValues.connected == false" class="text-align-center"
              style="height:55px;">
        <f7-col>
          <p class="mb0">An issue occurred, please try again.</p>
          <f7-link @click="refresh">Refresh</f7-link>
        </f7-col>
      </f7-row>
    </f7-block>

    <!-- Device info for nerds - testing -- >
    <f7-block strong inset v-if="this.$root.is_dev_user">
      <f7-row>
        <f7-col>
          <p class="mb0" style="margin-top:12px;">Stats for device: <b>{{ deviceStatsText }}</b></p>
        </f7-col>
      </f7-row>

      <f7-row>
        <f7-col>
          <pre>{{ deviceStatsForNerds }}</pre>
        </f7-col>
      </f7-row>
    </f7-block> -->


    <!-- light switch(s) and infos -->
    <f7-list inset class="mb5">
      <f7-list-item title="Automatic lights">
        <f7-icon slot="media" style="color: #62bb48;" f7="stopwatch"></f7-icon>
        <f7-toggle slot="after" :checked="device.is_automatic" @toggle:change="change($event)"></f7-toggle>
      </f7-list-item>

      <f7-list-item v-if="device.is_automatic" title="Start time" :after="device.start_time + 'h'"
                    link="/edit-garden/?time_trigger=1">
        <f7-icon slot="media" f7="play_circle" style="color: #62bb48;"></f7-icon>
      </f7-list-item>
    </f7-list>

    <f7-block v-if="!device.is_automatic" class="b-desc">
      Off: It’s best to switch them on and let UrbiGo control the amount of light for your plants.
    </f7-block>
    <f7-block v-else class="b-desc">
      On: UrbiGo controls the lights and makes sure your plants get their daily optimum. Keep the automatic lights on
      for the best growing results.
    </f7-block>

    <div v-if="!device.is_automatic">
      <f7-list inset class="mb5">
        <f7-list-item title="Manual switch">
          <f7-icon slot="media" style="color: #ffb91c; " :f7="lightIcon()"></f7-icon>
          <f7-preloader v-if="!$root.deviceLoaded || lightLoading" :size="22"
                        style="margin-top:5px; margin-bottom: 5px;"></f7-preloader>
          <span v-else-if="!deviceValues.connected" style="margin-top: 5px; margin-bottom: 4px;">-</span>
          <f7-toggle v-else slot="after" :checked="deviceValues.light" @toggle:change="lightAction()"></f7-toggle>
        </f7-list-item>
      </f7-list>
      <f7-block class="b-desc">
        Turn the lights on/off manually. Manual control is available only when the automation mode is turned off.
      </f7-block>
    </div>


    <f7-block inset strong style="margin-bottom: 10px;">
      Your plants had <b>{{ formatLightText(device.daily_light_hours) }}</b> of light Today,
      from <b>{{ device.daily_max_lights }} hours</b> needed. The light duration changes with your plant's growth stage.
    </f7-block>

  </f7-page>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      device: null,
      deviceValues: {
        temp: '-',
        water: '-',
        light: false
      },
      moreDevices: false,
      lightLoading: false,
      deviceStatsForNerds: null,  // this was for testing purpouse
      deviceStatsText: '',        // this was for testing purpouse
    }
  },
  methods: {
    chooseGarden() {
      let devices = (this.$root.getDevices())
      let buttons = []
      for (let i = 0; i < devices.length; i++) {
        buttons.push({text: devices[i].name, cssClass: 'text-align-center'})
      }

      buttons.push({text: 'Close', color: 'red', close: true, cssClass: 'text-align-center'})
      const app = this.$f7;
      app.dialog.create({
        title: 'Choose Garden',
        buttons: buttons,
        verticalButtons: true,
        onClick: this.changeGarden
      }).open();
    },
    refresh() {
      this.deviceValues = {temp: '-', water: '-', light: false}
      this.initDevice(this.device.id)
    },
    ping() {
      let deviceId = this.device.device_id
      this.deviceStatsText = 'Loading....'

      this.$http.get('refresh/ping?device_id=' + deviceId).then(
          response => {
            this.deviceStatsForNerds = response.data.data
            this.deviceStatsText = 'Loaded'
          },
          error => {
            this.deviceStatsText = 'Ping error'
          }
      )
    },
    changeGarden(dialog, index) {
      // user click on Close btn, nothing to do
      if (this.$root.getDevices().length == index) {
        return
      }

      // set new current device, and load the rest
      this.$root.setCurrent(index)
      this.device = this.$root.getCurrent()
      this.initDevice(this.device.id)
    },
    change(value) {
      // Well there is another bug and this solution solve the below one also...
      // So if the value is already changed somewhere else, or the value is not changed at all, than this values will be equal
      if (value == this.device.is_automatic) {
        return;
      }

      // there is bug in edit-garden page, so changes will trigger this as well
      let app = this.$f7
      if (app.view.current.router.url === '/tab1/') {
        this.device.is_automatic = value
        let data = {is_automatic: this.device.is_automatic, name: this.device.name}

        this.$http.put('green-cube/' + this.device.id, data).then({}, error => {
              this.$root.populateErrors("Error in saving data", error.data.message)
            }
        )
      }
    },
    initDevice(id) {
      this.$root.deviceLoaded = false;
      this.$http.get('green-cube/' + (id) + '/status').then(
          response => {
            this.deviceValues = response.data.data
            this.$root.deviceLoaded = true
          },
          error => {
            this.$root.populateErrors("Error", error.data.message)
            this.$root.deviceLoaded = true;
          }
      )
    },
    lightIcon() {
      if (this.lightLoading) {
        return 'arrow_clockwise_circle';
      }

      if (!this.$root.deviceLoaded) {
        return 'arrow_clockwise_circle';
      }

      if (!this.deviceValues.connected) {
        return 'nosign';
      }

      if (this.deviceValues.light) {
        return 'lightbulb_fill';
      } else {
        return 'lightbulb_slash';
      }
    },
    lightAction() {
      let id = this.device.id
      let action = this.deviceValues.light ? 0 : 1
      this.lightLoading = true

      this.$http.get('green-cube/' + id + '/light', {params: {action: action}}).then(
          response => {
            this.deviceValues.light = !!action
            this.lightLoading = false
          },
          error => {
            this.$root.populateErrors("Error", error.data.message)
            this.lightLoading = false
          }
      )
    },
    getWaterLevel(water) {
      let lvl = parseInt(water);
      let text;

      if (isNaN(lvl)) {
        text = '-';
      } else {
        if (lvl == '333.00') {
          text = 'good';
        } else if (lvl < 90) {
          text = 'empty';
        } else if (lvl < 200) {
          text = 'low';
        } else if (lvl < 450) {
          text = 'half';
        } else {
          text = 'full';
        }
      }

      return text;
    },
    formatTemp(temp) {
      return 'good'
      if (temp && temp != '-') {
        return Math.round(temp)
      }

      return temp
    },
    formatLight(light) {
      if (light) {
        return Math.floor(light)
      }

      return light
    },
    formatLightText(light) {
      light = this.formatLight(light)

      return light + (light == 1 ? ' hour' : ' hours')
    },
  },
  created() {
    this.device = this.$root.getCurrent()
    this.moreDevices = this.$root.hasMultiple()

    if (this.device) {
      this.initDevice(this.device.id)
    }

    // Stats for nerds
    // if (this.$root.is_dev_user && this.device) {
    //   this.ping()
    //   window.setInterval(() => {
    //     this.ping()
    //   }, 3000)
    // }
    // END - Stats for nerds
  },
  beforeCreate() {
    // If user is logged in and has not a device, redirect to the setup page!
    if (!this.$root.getDevices().length) {
      this.$f7router.navigate('/setup-device/', {force: true})
    }
  },
};

</script>

<style scoped>
.fs {
  font-size: 27px;
}

.b-desc {
  margin-top: 0px;
  color: #747474;
}

.mb5 {
  margin-bottom: 5px;
}

.mb0 {
  margin-bottom: 0px;
}

.fix-height ul {
  height: 48px;
}

.text-align-center {
  text-align: center;
}

/*
.edit-icon {
  float: right;
  color: #62bb48;
  margin-top: 6px;
}
*/

/* Card for anouncing V2 */
.demo-card-header-pic .card-header {
  height: 40vw;
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: right;
}
.demo-card-header-pic .card-content-padding .date{
  color: #8e8e93;

}


</style>